export default function (hours) {
    if (!hours) return "";
    let str = "";
    let it = [];
    for (let i = 0; i < hours.length; i++) {
        if (hours[i]) {
            it.push(i);
        } else {
            if (it.length) {
                if (str) str += ", ";
                str += it[0] + ":00-" + it[it.length - 1] + ":59";
            }
            it = [];
        }
    }
    if (it.length) {
        if (str) str += ", ";
        str += it[0] + ":00-" + it[it.length - 1] + ":59";
    }
    return str;
}