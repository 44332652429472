<template>
  <v-dialog v-model="model" width="400" persistent>
    <v-card class="dialog-ctx" dark>
      <v-card-title>
        <i18n path="logintitle" />
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('input', false)" color="error">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="login">
          <v-text-field v-model="card" :label="$t('card')" @keypress.enter="$refs.phone.focus()" />
          <VuePhoneNumberInput
            ref="phone"
            v-model="phone"
            size="lg"
            default-country-code="LV"
            :preferred-countries="['LV','EE','LT']"
            required
            :error="isPhoneError"
            clearable
            dark
            class="mt-4"
            :border-radius="10"
            :translations="{countrySelectorLabel: $t('phone.country'),  countrySelectorError: $t('phone.error'),  phoneNumberLabel: $t('phone.number'),  example: $t('phone.example')}"
            @update="isPhoneError=false"
          />
          <!-- <v-text-field ref="phone" v-model="phone" :label="$t('phone')" @keypress.enter="login" /> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-alert v-model="isError" dense dark type="error" dismissible>{{message}}</v-alert>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="login">
          <i18n path="loginbtn" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "LoginDialog",
  components: { VuePhoneNumberInput },
  props: { value: Boolean },
  data() {
    return {
      // card: "km001",
      // phone: "+37129131535",
      isPhoneError: false,
      card: null,
      phone: null,
      isError: false,
      message: null,
    };
  },
  computed: {
    model() {
      return this.value;
    },
  },
  methods: {
    login() {
      this.isError = false;
      this.message = null;
      const ph = this.$refs.phone;
      if (!this.phone || !ph.isValid) {
        this.isPhoneError = true;
        return;
      }
      this.$api
        .login({ card: this.card, phone: ph.results.formattedNumber })
        .then((r) => {
          this.$store.commit("session/setClient", r);
          this.$router.push("/services").catch(() => {});
          this.$emit("input", false);
        })
        .catch((e) => {
          this.isError = true;
          if (e.request && e.request.status == 401)
            this.message = this.$t("error.login");
          else this.message = e.message;
        });
    },
  },
};
</script>

<style>
</style>