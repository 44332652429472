<template>
  <v-app class="ma-0 pa-0">
    <div class="header-div-back"></div>
    <div class="header-div-text">
      <v-col align-self="center" cols="1">
        <img src="/img/logo.png" class="logo-img" @click="$router.push('/').catch(()=>{})" />
        <SelectLocale />
      </v-col>
      <div v-if="$store.getters['session/cid']" class="account-div">
        <v-icon dark>mdi-account</v-icon>
        <span class="ml-1 white--text text-h6">{{$store.getters['session/name']}}</span>
      </div>
      <v-col v-if="$vuetify.breakpoint.xs" class="header-link text-right" align-self="center">
        <v-btn text dark class="link-btn" x-large @click="d_menu=true">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
      </v-col>
      <v-col v-else class="header-link" align-self="center">
        <template v-if="$store.getters['session/cid']">
          <v-btn v-for="m in $api.cmenu" :key="m.text" text dark :to="m.route" class="link-btn">
            <v-icon v-if="m.icon" class="mr-1">{{m.icon}}</v-icon>
            <i18n :path="m.text" />
          </v-btn>
        </template>
        <template v-else>
          <v-btn v-for="m in $api.menu" :key="m.text" text dark :to="m.route" class="link-btn">
            <v-icon v-if="m.icon" class="mr-1">{{m.icon}}</v-icon>
            <i18n :path="m.text" />
          </v-btn>
        </template>
      </v-col>
      <v-col cols="1" v-if="!$vuetify.breakpoint.xs" align-self="center">
        <v-btn
          icon
          class="login-btn"
          dark
          @click="d_login=true"
          v-if="!$store.getters['session/cid']"
        >
          <v-icon>mdi-login</v-icon>
        </v-btn>
        <v-btn icon class="login-btn" dark v-else>
          <v-icon @click="$api.logout()">mdi-logout</v-icon>
        </v-btn>
      </v-col>
    </div>
    <v-main class="grey lighten-2 ma-0 pa-0">
      <v-container fluid class="full-height pa-0 ma-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <MenuDialog v-model="d_menu" @login="d_login=true" />
    <LoginDialog v-model="d_login" />
    <!-- <v-footer app>Copyright</v-footer> -->
    <div class="cp-div" v-if="!$vuetify.breakpoint.xs">
      <a href="https://mansklubs.lv" target="_blank">
        <div class="logotip-mansklubs">
          <span>Design by</span>
          <v-img src="/img/logo-blue.png" width="65"></v-img>
        </div>
      </a>
    </div>
  </v-app>
</template>

<script>
import SelectLocale from "@/components/Select-locale.vue";
import MenuDialog from "@/components/MenuDialog.vue";
import LoginDialog from "@/components/LoginDialog.vue";

export default {
  components: { MenuDialog, LoginDialog, SelectLocale },
  data: () => ({ drawer: null, d_login: false, d_menu: false }),
};
</script>
<style>
.logo-img {
  height: 52px;
  width: 52px;
  border-radius: 20px;
  /*    width: 5vh;
    margin-left: 20px;
    left: 20px;*/
}
.logo-img:hover {
  cursor: pointer;
}
.word-wrap{
  word-break: break-word;
}
.full-height {
  height: 100%;
}
.content-top-padding {
  padding-top: 95px;
}
.client-title-div {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.app-bar-class {
  background-color: #363535 !important;
  opacity: 0.2;
}

.header-div-text {
  position: absolute;
  width: 100%;
  /*  background: black;
    opacity: 0.4;*/
  z-index: 2;
  height: 86px;
  display: flex;
  text-align: center;
}

.header-div-back {
  position: absolute;
  width: 100%;
  background: black;
  z-index: 2;
  opacity: 0.4;
  height: 86px;
  display: flex;
}

.header-link {
  font-size: 24px;
  color: aliceblue;
}
.cp-div {
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  margin-right: 8px;
  margin-bottom: 5px;
  font-size: 9px;
}
/* @media only screen and (max-width: 600px) {
  .link-btn {
    font-size: 50px !important;
  }
} */
.account-div {
  position: absolute;
  right: 100px;
  top: 28px;
  display: flex;
}
.logotip-mansklubs {
  display: flex;
  align-items: center;
  font-size: 1rem;
}
</style>
