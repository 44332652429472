<template>
  <v-dialog v-model="model" persistent>
    <v-card class="dialog-ctx" dark>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('input', false)" color="error">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <template v-if="$store.getters['session/cid']">
          <v-row v-for="m in $api.cmenu" :key="m.text" justify="center" @click.prevent="goTo(m)">
            <i18n :path="m.text" class="menu-item" />
          </v-row>
          <v-row justify="center">
            <v-btn text dark class="link-btn" @click="logout">
              <i18n path="menu.logout" />
              <v-icon class="ml-2">mdi-logout</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-else>
          <v-row v-for="m in $api.menu" :key="m.text" justify="center" @click.prevent="goTo(m)">
            <i18n :path="m.text" class="menu-item" />
          </v-row>
          <v-row justify="center">
            <v-btn text dark class="link-btn" @click="login">
              <i18n path="menu.login" />
              <v-icon class="ml-2">mdi-login</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MenuDialog",
  props: { value: Boolean },
  data() {
    return {
      card: null,
      phone: null,
    };
  },
  computed: {
    model() {
      return this.value;
    },
  },
  methods: {
    goTo(m) {
      this.$router.push(m.route).catch(() => {});
      this.$emit("input", false);
    },
    login() {
      this.$emit("input", false);
      this.$emit("login");
    },
    logout() {
      this.$emit("input", false);
      this.$api.logout();
    },
  },
};
</script>

<style scoped>
.menu-item {
  color: white;
  font-size: 0.875rem;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
}
.menu-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* .dialog-ctx {
   background-color: rgb(44, 41, 41) !important;
  color: white !important 
} */
</style>