import currency from './currency';
import datetime from './datetime';
import dateonly from './dateonly';
import duration from './duration';
import timeinterval from './timeinterval';

export default {
    install(Vue) {
        Vue.filter('currency', currency);
        Vue.filter('dt-time', datetime);
        Vue.filter('dt-only', dateonly);
        Vue.filter('duration_filter', duration);
        Vue.filter('time_interval', timeinterval);
    }
}