<template>
  <v-carousel
    cycle
    height="100%"
    reverse-transition="fade-transition"
    transition="fade-transition"
    :show-arrows="false"
  >
    <v-carousel-item style="fluid-window" v-for="(item,i) in items" :key="i">
      <v-img :src="item.src" class="img-class">
        <div class="center-block">
          <i18n :path="'img'+(i+1)" class="carusel-text"/>
        </div>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      curimg: 0,
      items: [
        {
          src: "/img/bg-1.jpg",
        },
        {
          src: "/img/bg-2.jpg",
        },
        {
          src: "/img/bg-3.jpg",
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style scoped>
.img-class {
  width: 100%;
  height: 100vh;
}
.carusel-text{
  font-size: 70px;
}

@media screen and (max-width:650px) {
.carusel-text{
  font-size: 35px;
}
}
</style>
