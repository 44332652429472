const state = {
    sessionData: null,
}

const getters = {
    cid: state => {
        try {
            return state.sessionData.client.idx;
        } catch (error) {
            return false;
        }
    },
    name: state => {
        try {
            return state.sessionData.client.name;
        } catch (error) {
            return "";
        }
    },
    email: state => {
        try {
            return state.sessionData.client.email;
        } catch (error) {
            return "";
        }
    },
    phone: state => {
        try {
            return state.sessionData.client.phone;
        } catch (error) {
            return "";
        }
    },
    client: state => {
        try {
            return state.sessionData.client;
        } catch (error) {
            return {};
        }
    },
}
const actions = {
}
const mutations = {
    setClient: (state, payload) => {
        try {
            if (payload.client.idx == undefined) {
                state.sessionData = null;
            } else {
                state.sessionData = payload;
            }
        } catch (e) {
            state.sessionData = null;
        }
    },
}

const User = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default User;