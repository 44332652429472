import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import api from "@/api.js";
import store from "@/store/index.js"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/profile',
    name: 'Cabinet',
    meta: {
      auth: true
    },
    component: () => import('../views/Cabinet.vue')
  },
  {
    path: '/sales',
    name: 'Sales',
    meta: {
      auth: true
    },
    component: () => import('../views/Sales.vue')
  },
  {
    path: '/visits',
    name: 'Visits',
    meta: {
      auth: true
    },
    component: () => import('../views/Visits.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {
      auth: true
    },
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/services',
    name: 'Services',
    meta: {
      auth: true
    },
    component: () => import('../views/Services.vue')
  },
  {
    path: '/workouts',
    name: 'Workouts',
    meta: {
      auth: true
    },
    component: () => import('../views/Workouts.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next) {
  if (to.meta.auth) {
    if (!store.state.session.sessionData || !store.state.session.sessionData.idx) {
      api.ping().then(response => {
        if (!response.client.idx) {
          next('/');
        } else {
          store.state.session.sessionData = {
            ...response
          }
          next();
        }
      }).catch(() => {
        next('/');
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router