import Vue from 'vue'
import Vuex from 'vuex'
import Session from './session.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
      session: Session
  }
})
