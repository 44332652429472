import axios from "axios";
const API = process.env.NODE_ENV == "development" ? "https://192.168.112.114:5678/scapi" : "https://darbavieta.mogofit.lv/scapi";
const scapi = axios.create({
    crossDomain: true,
    withCredentials: true,
    baseURL: API,
});

const api = {

    login(cred) {
        return this.apiPostRequest("auth", cred);
    },
    async logout() {
        await this.apiPostRequest("v1/client/logout");
        this.$vm.$store.commit("session/setClient", null);
        this.$vm.$router.push("/").catch(() => {});
    },
    clientinfo() {
        return this.apiGetRequest("v1/client/info");
    },
    async testsession() {
        try {
            const result = await this.ping();
            if (!result.client) return false;
            this.$vm.$store.commit("session/setClient", result.client);
            return this.isLogged();
        } catch (error) {
            console.log("testsession", error)
            return false;
        }
    },
    ping() {
        return this.apiGetRequest("v1/client/ping");
    },
    sales() {
        return this.apiGetRequest("v1/client/sales");
    },
    visits() {
        return this.apiGetRequest("v1/client/visits");
    },
    visitDetail(idx) {
        return this.apiGetRequest("v1/client/visit/detail/" + idx);
    },
    getShopProducts() {
        return this.apiGetRequest("v1/client/shop/products");
    },
    services() {
        return this.apiGetRequest("v1/client/services");
    },
    workouts() {
        return this.apiGetRequest("v1/client/workouts");
    },
    getShopOrder(o, c) {
        return this.apiGetRequest("v1/client/shop/order/" + o + "/" + c);
    },
    getBuyLink(r) {
        return this.apiPostRequest("v1/client/shop/buy", r);
    },

    apiPostRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        if (!uri.startsWith("/")) uri = "/" + uri;
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            scapi.post(uri, data, pars).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    apiGetRequest(uri, pars, cache = false) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (!uri.startsWith("/")) uri = "/" + uri;
        if (!cache) pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            scapi.get(uri, {
                params: pars,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    isLogged() {
        try {
            if (!this.$vm.$store.getters["session/cid"]) {
                // if (!this.$vm.$route.path != "/") this.$vm.$router.push("/");
                return false;
            }
        } catch (error) {
            return false;
        }
        return true;
    },
    img_link(i, def = true) {
        if (!i && def) return API + "/img/default-avatar.jpg";
        return API + '/photos/' + i;
    },

    menu: {
        home: {
            route: "/",
            icon: "mdi-home",
            text: "menu.home"
        },
        about: {
            route: "/about",
            icon1: "mdi-information",
            text: "menu.about"
        },
        contacts: {
            route: "/contacts",
            icon1: "mdi-card-account-mail-outline",
            text: "menu.contacts"
        },
    },
    cmenu: {
        services: {
            route: "/services",
            text: "menu.services",
        },
        shop: {
            route: "/shop",
            text: "menu.shop",
        },
        sales: {
            route: "/sales",
            text: "menu.sales",
        },
        visits: {
            route: "/visits",
            text: "menu.visits",
        },
        workouts: {
            route: "/workouts",
            text: "menu.workouts",
        },
        client: {
            route: "/profile",
            text: "menu.client",
        },
    },
    getSavedLocaleAsStr() {
        return localStorage.getItem("lang");
    },
    setLocale(v) {
        localStorage.setItem("lang", v);
        this.$vm.$i18n.locale = v;
    },
    setVm(vm) {
        this.$vm = vm;
        this.$vm.$i18n.locale = this.getSavedLocaleAsStr();
    },
}
export default api;