import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import api from "@/api.js";
import i18n from './i18n'
import store from './store'
import filters from './filters'

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.use(filters);
Vue.use(require('vue-moment'));

const vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

api.setVm(vm);